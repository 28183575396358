import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// contexts
import { NetworkStatusProvider } from "../contexts/network-status-context";
import { LoanProvider } from "../contexts/loan-context";

// pages
import Loading from "../components/Loading";
import PrivateRoutes from "./PrivateRoutes";

// components
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import BRLSidebar from "../components/BRLSidebar";
import { getValueFromLocalStorage } from "../utils/local-storage";
import NotificationProvider from "../components/context/NotificationContext";

const Loans = lazy(() => import("../pages/Loans"));
const BRLLoans = lazy(() => import("../pages/BRLLoans"));
const MetaData = lazy(() => import("../pages/MetaData"));
const Login = lazy(() => import("../pages/Login"));

export default function App() {
  const { pathname } = window.location;
  return (
    <NotificationProvider>
      <NetworkStatusProvider>
        <LoanProvider>
          <Router>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route
                  path="/"
                  element={
                    getValueFromLocalStorage("token") ? (
                      <PrivateRoutes>
                        <Navigate to="/loans" />
                      </PrivateRoutes>
                    ) : (
                      <Navigate
                        to="/login"
                        state={{
                          pathname,
                        }}
                      />
                    )
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route
                  path={"/*"}
                  element={
                    <PrivateRoutes>
                      <>
                        <Header />

                        <Routes>
                          <Route
                            path="/loans"
                            element={
                              <>
                                {" "}
                                <div className="flex">
                                  <div className="h-[calc(100vh-72px)]">
                                    <Sidebar />
                                  </div>
                                  <Loans />
                                </div>
                              </>
                            }
                          />
                        </Routes>
                        <Routes>
                          <Route
                            path="/brl-loans"
                            element={
                              <>
                                {" "}
                                <div className="flex">
                                  <div className="h-[calc(100vh-72px)]">
                                    <BRLSidebar />
                                  </div>
                                  <BRLLoans />
                                </div>
                              </>
                            }
                          />
                        </Routes>
                        <Routes>
                          <Route
                            path="/dashboard"
                            element={
                              <>
                                {" "}
                                <div className="flex">
                                  <MetaData />
                                </div>
                              </>
                            }
                          />
                        </Routes>
                      </>
                    </PrivateRoutes>
                  }
                />
                <Route path="/page-not-found" element={<p>Not Found</p>} />
                <Route path="*" element={<Navigate to="/page-not-found" />} />
              </Routes>
            </Suspense>
          </Router>
        </LoanProvider>
      </NetworkStatusProvider>
    </NotificationProvider>
  );
}
